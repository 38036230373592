import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BitrateGraphs from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Graphs/BandwidthGraphsFlyout';
import CompressionGraphs from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/Graphs/CompressionGraphsFlyout';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/MultimediaPerm';
import UpdateBox from 'components/Web_User_Interface/1440p_Series/Multimedia/Image/PrimaryBoxUpdates';
import VideoTable from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/videoTable';
import PrimaryBox from 'components/Web_User_Interface/1440p_Series/Multimedia/Video/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Multimedia Menu // Video Settings",
  "path": "/Web_User_Interface/1440p_Series/Multimedia/Video/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The video settings allow you to adjust the quality of all 3 video streams of your camera. Please be advised that high quality settings come with high requirements for your computer hardware and network bandwidth.",
  "image": "./WebUI_1440p_SearchThumb_Multimedia_Video.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Multimedia_Video.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <SEOHelmet title='Multimedia Menu // Video Settings' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The video settings allow you to adjust the quality of all 3 video streams of your camera. Please be advised that high quality settings come with high requirements for your computer hardware and network bandwidth.' image='/images/Search/WebUI_1440p_SearchThumb_Multimedia_Video.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Multimedia_Video.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Multimedia/Video/' locationFR='/fr/Web_User_Interface/1440p_Series/Multimedia/Video/' crumbLabel="Video Settings" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "multimedia-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multimedia-menu",
        "aria-label": "multimedia menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multimedia Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The video settings allow you to adjust the quality of all 3 video streams from your camera. Please note that high quality settings come with high demands on your computer hardware and network bandwidth. Adjust the video quality accordingly to get a smooth video. Also see the item `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/"
      }}>{`RTSP Streaming`}</a>{` for accessing the camera streams with third-party software:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`WQHD`}</strong>{` / `}<em parentName="li">{`1440p`}</em>{` / `}<em parentName="li">{`2560x1440 Pixel`}</em>{` / `}<em parentName="li">{`3.68 Megapixel`}</em>{` / `}<em parentName="li">{`h.265a`}</em></li>
      <li parentName="ol"><strong parentName="li">{`Full HD`}</strong>{` / `}<em parentName="li">{`1080p`}</em>{` / `}<em parentName="li">{`1920x1080 Pixel`}</em>{` / `}<em parentName="li">{`2.07 Megapixel`}</em>{`  / `}<em parentName="li">{`h.264hp`}</em></li>
      <li parentName="ol"><strong parentName="li">{`WXGA`}</strong>{` / `}<em parentName="li">{`360p`}</em>{` / `}<em parentName="li">{`640x360 Pixel`}</em>{` / `}<em parentName="li">{`0.23 Megapixel`}</em>{` / `}<em parentName="li">{`h.264hp`}</em></li>
    </ol>
    <p>{`Note that you can set different values for all available streams. The `}<strong parentName="p">{`web user interface`}</strong>{` uses the `}<strong parentName="p">{`second stream`}</strong>{` by default. Our mobile apps use the second stream and the third for the camera preview.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/e24fe/1440p_Settings_Multimedia_Video.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7rAAAO6wFxzYGVAAACe0lEQVQoz32SS08TYRSG+ROGSI1VpDW0lnoNpXRmbDtMh0txZr65dDrlUuodkKIRYxQSXKiJigtNXIsrF4ILE+NGEzUmXhLDL3pMBxBIjIsn52y+N8/5zmnr6ekhlUpxLNVDd/wIOfMK5TtvKF1fRZ1fpTC3SqG5t6rNl4ildXqtJv19vaTTaToiHUSjUdqSySSJRIJEMkmsq5N8uUaw8By3+Rhv/il2cwUn5OlOnXuCf/MZY1dvM6SqnDqVIZU6Tjp9cjNwm3j8KLlsL865QRxjiMnAIXANrHIpxBzREFu9rircmT3PzNgUSqaEIxoUzpZoC+22iMViSLJMUBvHrwYhwnYwLREibBfbcXEcB+FP4rkBDaPOeGmcyZE6gwMabd3d3WHYpmEcSZLwfT98ZFkWpmmGtHpVVZFlmVwuh6LkMcwR6hMu00KwMuMS+MV/B1arVWzb/hu0O1CRJbLZPiSlgDE6xJCWYX62zovH1/CszP8Nd9u1qhAC4dUQlonl1/H8gCcPl/n29RO/11aZcbWdLbdq+Idbhp7n/Q3dDmxZ214N13Upjwxz++4iv3584fvH92ysv2Xl4vius9leiiRRC6r4FY+K5+2MLAQlXUctFtFLGv3ZLPfuLbHx+R2fX7/i54c1Hl4O9p5NKzAnSZhuwLDpMmqYO2MbZYpyFlnOcUaSOC1JlIcHGdZUDF1DFBVyJ47tNeyKxcgrEvdnbR7NmUxVLQzLxhYm9sQ05qVFKtVL3NBK3Cob5CWZfe3tdEQi7I9EOHAwutswQWzrsJcbKg8u5KmMDlDUdHRNRbcb6I0lLPs8C0qeW6qOnMkQPXSII52ddLY4fJg/ah3IXKD6uDsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/e4706/1440p_Settings_Multimedia_Video.avif 230w", "/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/d1af7/1440p_Settings_Multimedia_Video.avif 460w", "/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/7f308/1440p_Settings_Multimedia_Video.avif 920w", "/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/068a9/1440p_Settings_Multimedia_Video.avif 1073w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/a0b58/1440p_Settings_Multimedia_Video.webp 230w", "/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/bc10c/1440p_Settings_Multimedia_Video.webp 460w", "/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/966d8/1440p_Settings_Multimedia_Video.webp 920w", "/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/e9b9c/1440p_Settings_Multimedia_Video.webp 1073w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/81c8e/1440p_Settings_Multimedia_Video.png 230w", "/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/08a84/1440p_Settings_Multimedia_Video.png 460w", "/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/c0255/1440p_Settings_Multimedia_Video.png 920w", "/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/e24fe/1440p_Settings_Multimedia_Video.png 1073w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/81a9b937013ad9552fafe0d5cd4c3aa2/c0255/1440p_Settings_Multimedia_Video.png",
              "alt": "Web User Interface - 1440p Series - Multimedia Video",
              "title": "Web User Interface - 1440p Series - Multimedia Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "video-bitrate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#video-bitrate",
        "aria-label": "video bitrate permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Video Bitrate`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <UpdateBox mdxType="UpdateBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`This is an overview of the bandwidth and storage requirements for the live video stream and the video recordings. Note that the resolution and bitrate settings have a very large impact on both sizes.`}</p>
    <BitrateGraphs mdxType="BitrateGraphs" />
    <h2 {...{
      "id": "h26x-profile",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#h26x-profile",
        "aria-label": "h26x profile permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`h.26x Profile`}</h2>
    <p>{`h.26x is a "family" of standards that includes a number of different "profiles". All of these profiles rely heavily on temporal compression and motion prediction to reduce the amount of data in the stream. The three most commonly used profiles are `}<strong parentName="p">{`Baseline`}</strong>{`, `}<strong parentName="p">{`Main`}</strong>{`, and `}<strong parentName="p">{`High`}</strong>{` in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h.264`}</code>{`. Each of these profiles defines the specific encoding techniques and algorithms used to compress files.`}</p>
    <h3 {...{
      "id": "baseline-profil",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#baseline-profil",
        "aria-label": "baseline profil permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Baseline Profil`}</h3>
    <p>{`This is the simplest profile (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h.264bp`}</code>{`), used primarily for low-power, low-cost devices, including some video conferencing and mobile applications. Baseline profiles can achieve a compression ratio of about 1000:1, meaning that a stream of 1 Gbps can be compressed to about 1 Mbps. They use 4:2:0 chrominance sampling, i.e. color information is sampled at half the vertical and horizontal resolution of the black and white information. `}</p>
    <h3 {...{
      "id": "main-profil",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#main-profil",
        "aria-label": "main profil permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Main Profil`}</h3>
    <p>{`The main profile (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h.264mp`}</code>{`) contains all the features of Baseline, but with improved image prediction algorithms. It is used for SD digital television broadcasts that use the MPEG-4 format, but not for HD broadcasts.`}</p>
    <h3 {...{
      "id": "high-profil",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#high-profil",
        "aria-label": "high profil permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`High Profil`}</h3>
    <p>{`h.264 High Profile (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h.264hp`}</code>{`) is the most efficient and powerful profile of the h.264 family and is the most important profile for transmission and storage on media, especially for HDTV and Bluray media formats. It can achieve a compression ratio of about 2000:1. The High Profile also uses adaptive transformation, which can choose between 4x4 and 8x8 pixel blocks. For example, 4x4 blocks are used for high-detail parts of the image, while low-detail parts are compressed with 8x8 blocks. The result is the preservation of video image quality while reducing the required network bandwidth by up to 50 percent. By applying h.264 High Profile compression, a stream can be compressed to about 512 Kbps at 1 Gbps.`}</p>
    <h3 {...{
      "id": "high-efficiency",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#high-efficiency",
        "aria-label": "high efficiency permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`High Efficiency`}</h3>
    <p><em parentName="p">{`High Efficiency Video Coding`}</em>{` (`}<strong parentName="p">{`HEVC`}</strong>{`), also known as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h.265a`}</code>{`, is a video compression standard developed as a successor to the widely used `}<strong parentName="p">{`Advanced Video Coding`}</strong>{` (AVC or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h.264`}</code>{`). Compared to AVC, HEVC offers 25% to 50% better data compression at the same video quality, or much better video quality at the same bit rate.`}</p>
    <p>{`The first stream, with the highest resolution, is output in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h.265`}</code>{` by default to ensure the smallest possible file size - with the best quality - for the video recordings. However, the second, middle stream, used in the WebUI, defaults to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h.264`}</code>{` because `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`h.265`}</code>{` compression is not yet universally supported in `}<a parentName="p" {...{
        "href": "https://caniuse.com/hevc"
      }}>{`web browsers`}</a>{`.`}</p>
    <CompressionGraphs mdxType="CompressionGraphs" />
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoTable mdxType="VideoTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      